<template>
    <div class="p-grid">
        <div class="p-col-12">

            <div  class="card docs">
                <InfiniteProgress v-show="loading"></InfiniteProgress>

                <div v-html="data" class="p-fluid card-content"></div>
            </div>
        </div>
    </div>
</template>

<script>
    import ProfileService from "../service/ProfileService";
    import showdown from 'showdown';
    import InfiniteProgress from '../components/InfiniteProgress.vue';

    export default {
        data(){
            return {
                data: '',
                loading: true,
            }
        },
        created(){
            this.profileService = new ProfileService();
        },
        mounted(){
            this.profileService.getHelp()
                .then(res => {
                    if(res.error === true){
                        throw new Error(res.data.message);
                    }

                    /**
                     * @see https://github.com/showdownjs/showdown
                     * @type {Converter}
                     */
                    try {
                        let converter = new showdown.Converter();
                        this.data = converter.makeHtml(res.data.data);
                    } catch (e) {
                        console.log(e);
                    }
                })
                .catch(err => {
                    this.$toast.add({severity:'error', summary: 'Une erreur est survenue', detail:err || 'erreur inconnue', life: 3000});
                })
                .finally(() => {
                    this.loading = false;
                })
        },
        methods: {

        },
        computed: {

        },
        mixins: [],
        components: {
            InfiniteProgress,
        }
    }
</script>

<style scoped>
pre {
    border-left: 10px solid #7fbcec;
    background: #f0f0f0;
    font-family: Consolas,Monaco,Andale Mono,Ubuntu Mono,monospace;
    text-align: left;
    white-space: pre;
    padding: 1em;
    overflow: auto;
}

i {
    background-color: #fcf2a7;
    font-family: monaco,Consolas,Lucida Console,monospace;
    font-weight: 700;
    font-style: normal;
    font-size: 14px;
    padding: 2px;
}
</style>
